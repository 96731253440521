<template>
  <div style="width: 100%; margin: 20px auto;background-color: #F3F5F9;">

    <div class="inputStyle">
      <el-select type="text" class="inputSelect" v-model="project_codeStr" @change="getMessageData">



        <el-option
          v-for="item in project_codes"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>

      </el-select>
    </div>
    

    <!-- <div class="session-title" @click="toTranscript">查看电子成绩单</div> -->
    <div v-if="showChinese != 'english'" class="session-title1" @click="exportAward" style="width: 200px">
      {{$t('m.xiazaizhengshu')}}
    </div>
    <div v-if="showChinese == 'english'" class="session-title1" @click="exportAward" style="width: 300px">
      {{$t('m.xiazaizhengshu')}}
    </div>
    <div style="clear: both"></div>

    <div class="my-events">
      <div v-for="(item, index) in rankList" :key="index">
        <!-- <div class="numLab">{{ 'Total Socores： ' + item.total_score +'/'+ item.full_score }}</div> -->
        <!-- <div class="numLab">{{ 'Total Socores： ' + item.total_score +'/'+ item.full_score +   ' &nbsp;&nbsp;&nbsp;&nbsp;        Rank： ' +  item.percentile}}</div> -->
        <div class="events-item">
          <div>
            <!-- <div  class="showLable">{{ item.award_info.award_status == 'no award' ? '未晋级' :( item.award_info.award.award_rise == '晋级' ? ('已' +item.award_info.award.award_rise) : item.award_info.award.award_rise)}}</div> -->
            <!-- <div @click="showDetail(item)" class="showLable">查看成就</div> -->
            <!-- <div
              @click="toCertificate(item)"
              v-if="item.award_info.award_status == 'success'"
              class="showLable1"
            >
              查看证书
            </div> -->
          </div>
          <div class="scoreLable1">
            Score：
          </div>

          <div v-if="item.project_code == '22-23CEO_Application' && item.application_event == 'Financial Literacy'" class="scoreLable">
            {{ item.total_score}}
          </div>
          <div v-else class="scoreLable">
            {{ item.total_score + " / " + item.full_score }}
          </div>
          


          <div class="scoreLable3">
            {{ item.award_info.award ? ( item.award_info.award.award_rise ? item.award_info.award.award_rise : '') : '' }}
          </div>

          <div style="clear: both"></div>

          <div style="padding: 0px 36px; position: relative">
            <div class="event_name">{{ item.application_event }}</div>
            
            <!-- <div class="event_name1">   <span>National Leadership Conference</span> </div> -->


            

            <!-- <div style="position: relative;margin-top: 10px;height: 60px;display: flex;flex-direction: row">
                        <div style="display: flex;flex-direction: row;align-items: center;">
                        <div style="color: #FFB041;font-size: 18px;font-weight: 600">开始时间</div>
                        <div style="color: #FD5900;font-size: 18px;font-weight: 600;margin-left: 10px">
                            {{ 1111}}
                        </div>
                        </div>
                        <div
                            style="background-color: red;top: 50%;transform: translateY(-50%);display: flex;flex-direction: row;align-items: center;position: absolute;right: 0">
                            <div class="event-status">状态</div>
                            <div class="event-entry" style="margin-left: 10px" @click="goExam(item)">进入考场</div>
                        </div>
                    </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getGroupAwards,
  getAwards,
  getMyAward,
  findMyTranscript,
  checkTranscriptOpen,
  getUserOpenProjectCode
} from "../../api/index";
import { getUserId, getProjectCode,getProjectLanguage } from "../../utils/store";
import "../../assets/common/font.css";

export default {
  data() {
    return {
      events: [
        "Artithon - Academic Marathon in Art",
        "Biothon - Academic Marathon in Biology",
        "Chemithon - Academic Marathon in Chemistry",
        "Econthon - Academic Marathon in Economics",
        "Geothon - Academic Marathon in Geography",
        "Historithon - Academic Marathon in History",
        "Mathethon - Academic Marathon in Mathematics",
        "Physithon - Academic Marathon in Physics",
        "Psychothon - Academic Marathon in Psychology",
      ],
      currentEvent: "Artithon - Academic Marathon in Art",
      groupAwards: [],
      personalAwards: [],
      myAward: {},
      show: false,
      showGroup: false,
      showCom: false,
      rankList: [],
      riseStr:'',
      showChinese:getProjectLanguage(),
      project_codes : [],
      project_codeStr : this.$t('m.messageProjectCode')
    };
  },
  mounted() {
    // this.getGroupAwards()
    // this.getAwards()
    // this.getMyAward()
    // this.findMyTranscript();
    this.getUserOpenProjectCode();
  },
  methods: {

    getUserOpenProjectCode(){


      var foreign = 'false'

      if (this.showChinese == 'english'){
        foreign = 'true'
      }

      getUserOpenProjectCode(getUserId(),foreign).then((res) => {
        // console.log(1111,res)
        this.project_codes = res.data
      });
    },
    getMessageData(){
      this.findMyTranscript()
    },
    findMyTranscript() {


      if (this.project_codeStr == this.$t('m.messageProjectCode')){

        this.$message.error(this.$t('m.messageProjectCode'));
        return
      }

      findMyTranscript(getUserId(), this.project_codeStr).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.rankList = res.data.data.score_info;
        }
      });
    },

    handleEventName(event) {
      if (event == undefined) {
        return "";
      }
      let event_array = event.split(" ");
      console.log(111, event, event_array);
      return event_array[0];
    },

    showDetail(item) {
      this.$router.push({
        path: "/awardDetail",
        query: {
          awardId: "",
          data: item,
        },
      });
    },

    exportAward() {


      if (this.project_codeStr == this.$t('m.messageProjectCode')){

        this.$message.error(this.$t('m.messageProjectCode'));
        return
      }
      checkTranscriptOpen(this.project_codeStr).then((res) => {
        if (res.data) {
          this.$router.push({
            path: "/awardExport",
            query: {
              project_codeStr: this.project_codeStr
            },
          });
        } else {
          this.$message.error(this.$t('m.chengjiweigongbu'));
        }
      });
    },

    getAwards() {
      getAwards("21-22FBLAbiz_china", "Business Communication").then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.personalAwards = res.data.data;
        }
      });
    },
    getMyAward() {
      getMyAward("6158c9dba4640b2c518b4573").then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.myAward = res.data.data;
        }
      });
    },
    getGroupAwards() {
      getGroupAwards("21-22FBLAbiz_china", "Business Communication").then(
        (res) => {
          if (res.data.code === 0) {
            this.groupAwards = res.data.data;
          }
        }
      );
    },
    chooseEvent(item) {
      this.currentEvent = item;
      this.getGroupAwards();
      this.getAwards();
    },
    upfirstName(name) {
      let transferName = name.toLowerCase();
      return transferName.charAt(0).toUpperCase() + transferName.slice(1);
    },
    rank(number) {
      if (number === 1) {
        return "st";
      } else if (number === 2) {
        return "nd";
      } else if (number === 3) {
        return "rd";
      } else {
        return "th";
      }
    },
    printPersonal() {
      let url = this.$router.resolve({
        path: "/blank",
      });
      let newWindow = window.open(url.href, "_blank"); //打印窗口要换成页面的url
      let obj = document.getElementById("cerP");

      let docStr = obj.innerHTML;

      newWindow.document.write(docStr);

      newWindow.document.close();

      newWindow.print();

      newWindow.close();
    },
    toTranscript() {
      checkTranscriptOpen(getProjectCode()).then((res) => {
        if (res.data) {
          this.$router.push({
            path: "/transcript",
          });
        } else {
          this.$message.error(this.$t('m.chengjiweigongbu'));
        }
      });
    },
    toCertificate(data) {
      if (
        data &&
        data.award_info &&
        data.award_info.award_status === "success"
      ) {
        this.$router.push({
          path: "/certificate",
          query: {
            score_id: data._id.$id,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-dialog {
  width: 320mm;
}
.my-events {
  width: calc(100% - 40px);
  margin-top: 15px;
  margin-right: 40px;
  margin-left: 20px;
  
}
.numLab {
  font-size: 18px;
  color: white;
  font-family: Montserrat-SemiBold;
}

.placeLable {
  position: absolute;
  display: flex;
  right: 4px;
  width: 170px;
  height: 60px;
  color: white;
  z-index: 3;
  // background-color: red;
  top: 12px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  font-family: Montserrat-SemiBold;
}
.showLable2 {
  position: absolute;
  right: 230px;
  z-index: 3;
  width: 100px;
  height: 40px;
  color: #273161;
  font-size: 16px;
  border-radius: 6px;
  // background-color: #E5880E;
  top: 75%;
  transform: translateY(-50%);
  flex-direction: row;
  text-align: center;
  line-height: 40px;
  align-content: center;
  font-family: Montserrat-SemiBold;
}
.showLable {
  position: absolute;
  right: 120px;
  z-index: 3;
  width: 100px;
  height: 40px;
  color: #273161;
  font-size: 16px;
  border-radius: 6px;
  // background-color: #E5880E;
  top: 75%;
  transform: translateY(-50%);
  flex-direction: row;
  text-align: center;
  line-height: 40px;
  align-content: center;
  font-family: Montserrat-SemiBold;
}

.showLable1 {
  position: absolute;
  right: 10px;
  z-index: 3;
  width: 100px;
  height: 40px;
  color: #273161;
  font-size: 16px;
  border-radius: 6px;
  top: 75%;
  transform: translateY(-50%);
  flex-direction: row;
  text-align: center;
  line-height: 40px;
  align-content: center;
  font-family: Montserrat-SemiBold;
}
.event_name1{
  font-size: 12px;
  width: 250px;
  height: 20px;
  line-height: 20px;
  margin-top: 0px;
  font-weight: 900;
  text-align: center;
  color: #E5880E;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #FFEDD5;
}
.event_name {
  font-size: 30px;
  margin-top: 0px;
  font-weight: 600;
  color: #1B213D;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Montserrat-SemiBold;
}
.scoreLable1{
  position: absolute;
  right: 30px;
  font-size: 18px;
  top: 5%;
  font-weight: 600;
  width: 300px;
  color: #CBCDD4;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  font-family: Montserrat-SemiBold;
}
.scoreLable {
  position: absolute;
  right: 30px;
  font-size: 24px;
  top: 30%;
  font-weight: 600;
  width: 300px;
  color: #E5880E;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  font-family: Montserrat-SemiBold;
}
.scoreLable3 {
  position: absolute;
  right: 30px;
  font-size: 24px;
  top: 60%;
  font-weight: 600;
  width: 80%;
  color: #E5880E;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  font-family: Montserrat-SemiBold;
}
.events-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 110px;
  margin-right: 45px;
  margin-bottom: 30px;
  margin-top: 15px;
  font-family: Montserrat-SemiBold;
  background: white;
  position: relative;
}
.inputSelect {
    width: 150px;
    height: 40px;
    margin: 0 auto;
    

  }
.inputStyle {
  width: 100%;
  height: 50px;
  margin-left: 10px;
  .el-select {
    .el-input__inner {
      border: 2px solid #4E8AFC;
    }
  }
}
.session-title {
  float: left;
  margin-left: 20px;
  font-weight: 600;
  color: #4468EC;
  font-size: 18px;
  align-content: center;
  text-align: center;
  width: 150px;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  line-height: 40px;
  font-family: Montserrat-SemiBold;
  cursor: pointer;
  border: 1px solid #4468EC;
}

.session-title1 {
  float: left;
  font-weight: 600;
  color: #4468EC;
  font-size: 18px;
  align-content: center;
  text-align: center;
  width: 130px;
  margin-left: 10px;
  height: 38px;
  border-radius: 4px;
  background-color: white;
  line-height: 38px;
  font-family: Montserrat-SemiBold;
  border: 1px solid #4468EC;
  cursor: pointer;
}
</style>
